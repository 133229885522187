<template>
    <div class="content_title" >
        <div class="content_title_wrapper" :style="titleStyle">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
  name: 'content-title',
  props: {
    justify: {
      type: String,
    },
  },
  computed: {
    titleStyle () {
      return {
        justifyContent: this.justify,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.content_title {
    display: flex;
    align-items: center;
    height: 42px;
    margin-top: -12px;
    margin-bottom: 16px;
    font-size: 16px;
    position: relative;
    color: #000;

    .content_title_wrapper{
        flex:1;
        display: flex;
        align-items: center;
    }

    &:before{
        width: 5px;
        height: 24px;
        content: '';
        background: rgb(37, 119, 227);
        margin-right: 10px;
    }

    &:after {
      content:'';
      position: absolute;
      // width: 100%;
      left:-12px;
      right: -12px;
      bottom: 0;
      height: 1px;
      background-color: #e8eaec;
    }
}
</style>
