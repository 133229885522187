/*
 * @Date: 2021-09-29 10:21:46
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-29 10:23:18
 * @Description: 通用数据
 * @FilePath: \sass_web\maintenance_expert\src\store\common\state.js
 */

export default {
  closeOnClickModal: false,
  appendToBody: true,
  destroyOnClose: true,
  taxRateCache: [],
  supplierCache: null,
  annexNameCache: [],
  measureUnitCache: [],
  jifeiLeimuCache: [],
  qualityAssTypeCache: [],
  rateCache: 0,
};
