export default {
  UPDATE_TAX_RATE (state, taxRate) {
    state.taxRateCache = taxRate;
  },
  UPDATE_SUPPLIER (state, supplier) {
    state.supplierCache = supplier;
  },
  UPDATE_ANNEX_NAME (state, annexName) {
    state.annexNameCache = annexName;
  },
  UPDATE_MEASURE_UNIT (state, measureUnit) {
    state.measureUnitCache = measureUnit;
  },
  UPDATE_JIFEI_LEIMU (state, jifeiLeimu) {
    state.jifeiLeimuCache = jifeiLeimu;
  },
  UPDATE_QUALITY_ASS_TYPE (state, qualityAssType) {
    state.qualityAssTypeCache = qualityAssType;
  },
  UPDATE_RATE (state, rate) {
    state.rateCache = rate;
  },
};
