<template>
    <div class="content_block">
        <slot name="title">
            <content-title v-if="title">{{title}}</content-title>
        </slot>
        <slot></slot>
    </div>
</template>
<script>

import ContentTitle from './contentTitle.vue';
export default {
  name: 'content_block',
  components: {
    ContentTitle,
  },
  props: {
    title: {
      type: String,
    },
  },
};
</script>
<style lang="scss">
  .content_block {
    background-color: white;
    border-radius: 4px;
    padding: 12px;

    &+.content_block{
        margin-top: 20px;
    }

    .content_block+.content_block{
      margin-top: 0;
      border-top:  1px solid rgba(36,40,50,0.1);
    }
  }
</style>
