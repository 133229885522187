import ContentTitle from '@/components/common/layout/contentTitle.vue';
import ContentBlock from '@/components/common/layout/contentBlock';
import BottomPanel from '@/components/common/bottomPanel/index.vue';
import QueryForm from '@/components/common/queryForm.vue';
import TableWithPagination from '@/components/common/TableWithPagination';
import { CheckTag, CheckTagItem } from '@/components/common/CheckTag';
import Upload from '@/components/common/upload';

// 本地localstorage缓存
let local = {
  set: function (key, value) {
    if (typeof (value) === 'object') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  },
  get: function (key) {
    return localStorage.getItem(key) || '';
  },
  remove: function (key) {
    localStorage.removeItem(key);
  },
  clear: function () {
    localStorage.clear();
  },
};
// 本地session缓存
let session = {
  set: function (key, value) {
    if (typeof (value) === 'object') {
      value = JSON.stringify(value);
    }
    sessionStorage.setItem(key, value);
  },
  get: function (key) {
    return sessionStorage.getItem(key) || '';
  },
  remove: function (key) {
    sessionStorage.removeItem(key);
  },
  clear: function () {
    sessionStorage.clear();
  },
};
// 确认框
function confirmAlert (msg = '确认要删除该数据？', title = '温馨提示', settings = {}, type = '') {
  Object.assign(settings, { // 合并对象
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
    type: type,
  });
  return this.$confirm(`<p>${msg}</p>`, title, settings);
}
// 提示信息
function JZShowAlert (msg, type = 'success') {
  return this.$message({
    message: msg || '操作成功',
    type: type,
  });
}
export default {
  install: function (Vue) {
    Vue.prototype.$local = local;
    Vue.prototype.$session = session;
    Vue.prototype.$JZShowAlert = JZShowAlert;
    Vue.prototype.$confirmAlert = confirmAlert;
    Vue.component('ContentTitle', ContentTitle);
    Vue.component('ContentBlock', ContentBlock);
    Vue.component('BottomPanel', BottomPanel);
    Vue.component('QueryForm', QueryForm);
    Vue.component('TableWithPagination', TableWithPagination);
    Vue.component('CheckTag', CheckTag);
    Vue.component('CheckTagItem', CheckTagItem);
    Vue.component('Upload', Upload);
  },
};
