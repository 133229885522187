<template>
  <div :class="className">
    <el-upload
        v-if="type === 'list'"
        :limit="limit"
        :accept="accept"
        :multiple="multiple"
        :action="uploadDomain"
        :disabled="disabled"
        list-type="picture-card"
        :data="qiniuData"
        :before-upload="handleBeforeUpload"
        :on-exceed="handleLimitExceed"
        :on-success="uploadSuccess"
        :on-remove="handleImageRemove"
        :file-list="fileList">
        <i slot="default" v-if="!disabled" class="el-icon-plus" ></i>
        <div slot="file" slot-scope="{file}">
            <img
            class="el-upload-list__item-thumbnail"
            :src="getDefaultCover(file)" alt=""
            >
            <span class="el-upload-list__item-actions">
            <span
                class="el-upload-list__item-preview"
                @click="handleImagePreview(file)"
            >
                <i class="el-icon-zoom-in"></i>
            </span>
            <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleImageRemove(file)"
            >
                <i class="el-icon-delete"></i>
            </span>
            </span>
        </div>
    </el-upload>
    <el-upload v-else
        :disabled="disabled"
        :limit="limit"
        :accept="accept"
        :action="uploadDomain"
        :file-list="fileList"
        :show-file-list="showFileList"
        :data="qiniuData"
        :multiple="multiple"
        :on-preview="handleImagePreview"
        :before-upload="handleBeforeUpload"
        :on-exceed="handleLimitExceed"
        :on-success="uploadSuccess"
        :on-remove="handleImageRemove">
        <el-button name="trigger" size="mini" v-if="buttonText === '上传'"  type="primary" plain icon="el-icon-upload2" style="color: #409EFF;background: #ecf5ff;border-color: #b3d8ff;">上传</el-button>
        <el-button slot="trigger" size="small" v-else type="primary">{{buttonText}}</el-button>
    </el-upload>
    <div v-if="tip && !disabled" class="el-upload__tip" slot="tip" >
      <b>注：</b>
      <span>
        {{limit !==null ? '只能上传': `最多上传${limit}张`}}
        <span style="color:red">{{acceptDesc}}</span>
        类型的文件，且文件大小不可超过
        <span style="color:red">{{maxSize}}MB</span>
      </span>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import Emitter from '@/mixins/emitter';
import Common from '@/api/common';
import { mapGetters } from 'vuex';
export default {
  name: 'upload',
  mixins: [Emitter],
  props: {
    // 是否只读
    disabled: {
      type: Boolean,
    },
    // 展示的图片列表
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tip: {
      type: Boolean,
      default: true,
    },
    // 上传类型 list：卡片列表  single:单独上传按钮
    type: {
      type: String,
      default: 'list',
    },
    label: {
      type: String,
      default: () => {
        return '附件';
      },
      immediate: true,
    },
    // 显示附件列表
    showFileList: {
      type: Boolean,
      default: true,
    },
    // 组件大小，有normal和small
    size: {
      type: String,
      default: () => {
        return 'small';
      },
    },
    // 限制上传哪些格式的文件，默认为图片
    accept: {
      type: String,
      default: () => {
        return 'image/png,image/jpeg,image/jpg,image/bmp';
      },
    },
    // 上传文件数量限制,默认不限
    limit: {
      type: Number,
      default: () => {
        return Infinity;
      },
    },
    // 附件大小限制，默认不限
    maxSize: {
      type: Number,
      default: () => {
        return Infinity;
      },
    },
    multiple: {
      type: Boolean,
      default: ()=>{
        return true;
      },
    },
    buttonText: {
      type: String,
      default: ()=>{
        return '选取文件';
      },
    },
  },
  data () {
    return {
      uploadDomain: 'https://upload.qiniup.com',
      qiniuData: {
        key: '', // 图片名字处理
        token: '', // 七牛云token
        data: {},
      },
      downloadDomain: '',
      fileList: _.cloneDeep(this.value) || [],
      copyFileList: _.cloneDeep(this.value) || [],
    };
  },
  computed: {
    className: function () {
      let classes = ['attach_list'];
      if (this.disabled) {
        classes.push('attach_list--readonly');
      }
      classes.push(`attach_list--${this.size}`);
      return classes;
    },
    acceptDesc () {
      let desc = [];
      this.accept && this.accept.split(',').forEach(s => {
        let d = this.fileTypeMap.get(s);
        d && desc.push(d);
      });
      return desc.join(',');
    },
    ...mapGetters([
      'currentUserInfo',
    ]),
  },
  watch: {
    value: {
      deep: true,
      handler: function () {
        if (!_.isEqual(this.value, this.copyFileList)) {
          this.fileList = _.cloneDeep(this.value);
        }
      },
    },
    fileList: {
      deep: true,
      handler: function () {
        if (!_.isEqual(this.fileList, this.copyFileList)) {
          this.copyFileList = this.fileList ? _.cloneDeep(this.fileList) : [];
        }
      },
    },
  },
  methods: {
    handleLimitExceed () {
      this.$message.warning(`超过最大上传数量 ${this.limit}`);
    },
    handleImagePreview (file) {
      let url = '';
      if (file.url) {
        url = file.url;
      } else {
        url = process.env.VUE_APP_HOST + file.response.key;
      }
      window.open(url, '_blank');
    },
    handleImageRemove (file) {
      this.$emit('remove', file);
      let index = this.copyFileList.findIndex(t => t.url === file.url || t.uid === file.uid);
      if (index >= 0) {
        this.copyFileList.splice(index, 1);
        this.fileList = _.cloneDeep(this.copyFileList);
        let imageList = _.cloneDeep(this.copyFileList);
        this.$emit('input', imageList);
        this.dispatch('ElFormItem', 'el.form.change', imageList);
      }
    },
    handleBeforeUpload (file) {
      if (this.accept && this.accept !== '*') {
        let isMatchFileType = this.accept.indexOf(file.type) >= 0;
        if (!isMatchFileType) {
          let desc = [];
          this.accept.split(',').forEach(s => {
            let d = this.fileTypeMap.get(s);
            d && desc.push(d);
          });
          this.$message.warning(`${this.label}仅支持${desc.join(',')}格式`);
          return;
        }
      }

      if (file.size / 1024 / 1024 > this.maxSize) {
        this.$message.warning(`${this.label}大小不能超过${this.maxSize}MB`);
        return;
      }
      this.qiniuData.data = file;
      this.qiniuData.key = `${this.currentUserInfo.id}/${new Date().getTime()}/${file.name}`;
    },
    getDefaultCover (file) {
      if (file?.type && file?.type.indexOf('image') >= 0) {
        return file.url;
      }
      let fileType = '';
      if (file.raw && file.raw.type) {
        fileType = this.fileTypeMap.get(file.raw.type) || '';
      } else {
        let spl = file.url.split('.');
        fileType = spl[spl.length - 1];
      }
      let url = '';
      switch (fileType.toUpperCase()) {
      case 'PNG':
      case 'JPEG':
      case 'JPG':
      case 'GIF':
      case 'BMP':
        url = file.url;
        break;
      case 'PDF':
        url = require('@/assets/imgs/office/preview_pdf.png');
        break;
      case 'DOC':
      case 'DOCX':
        url = require('@/assets/imgs/office/preview_doc.png');
        break;
      case 'XLS':
      case 'XLSX':
        url = require('@/assets/imgs/office/preview_excel.png');
        break;
      case 'PPT':
      case 'PPTX':
        url = require('@/assets/imgs/office/preview_ppt.png');
        break;
      default:
        url = require('@/assets/imgs/office/preview_common.png');
        break;
      }
      return url;
    },
    uploadSuccess (response, file) {
      let attach = {
        url: `${this.downloadDomain}/${response.key}`,
        attachId: response.hash,
        attachName: response.key,
        name: response.key,
        uid: file.uid,
      };
      this.$emit('success', attach);
      this.copyFileList.push(_.cloneDeep(attach));
      let imageList = _.cloneDeep(this.copyFileList);
      this.$emit('input', imageList);
      this.dispatch('ElFormItem', 'el.form.change', imageList);
    },
  },
  created () {
    let fileTypeMap = new Map();
    fileTypeMap.set('image/png', 'PNG');
    fileTypeMap.set('image/jpeg', 'JPEG');
    fileTypeMap.set('image/jpg', 'JPG');
    fileTypeMap.set('image/gif', 'GIF');
    fileTypeMap.set('image/bmp', 'BMP');
    fileTypeMap.set('application/pdf', 'PDF');
    fileTypeMap.set('application/msword', 'DOC');
    fileTypeMap.set('application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'DOCX');
    fileTypeMap.set('application/vnd.ms-excel', 'XLS');
    fileTypeMap.set('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'XLSX');
    fileTypeMap.set('application/vnd.ms-powerpoint', 'PPT');
    fileTypeMap.set('application/vnd.openxmlformats-officedocument.presentationml.presentation', 'PPTX');
    this.fileTypeMap = fileTypeMap;
    if (!this.disabled && !this.qiniuData.token) {
      Common.getQinNiuToken().then(res => {
        this.qiniuData.token = res.body.token;
        this.downloadDomain = res.body.domain;
      });
    }
  },
};
</script>
<style lang="scss" scoped>

.attach_list {
  // margin-bottom: 16px;
  width: 100%;

  &.attach_list--readonly {
    /deep/  .el-upload--picture-card {
        display: none;
    }
  }

  &.attach_list--normal {
    /deep/ .el-upload-list--picture-card {
      .el-upload-list__item {
        width: 100px;
        height: 110px;
      }
    }

    /deep/.el-upload--picture-card {
      width: 100px;
      height: 110px;
    }
  }

  &.attach_list--small {
    /deep/.el-upload-list--picture-card {
      .el-upload-list__item {
        width: 80px;
        height: 80px;
      }
    }

    /deep/.el-upload--picture-card {
      width: 80px;
      height: 80px;
    }
  }

  &.attach_list--mini {
    /deep/.el-upload-list--picture-card {
      .el-upload-list__item {
        width: 60px;
        height: 60px;
      }

      .el-upload-list__item-actions:hover span {
        font-size: 16px;
      }

      .el-upload-list__item-actions span + span {
        margin-left: 0px;
      }
    }

    /deep/.el-upload--picture-card {
      width: 60px;
      height: 60px;
    }
  }

  /deep/.el-upload-list--picture-card {
    .el-upload-list__item {
      margin-right: 16px;

      & > div {
        height: 100%;
        width: 100%;
      }
    }
  }

  /deep/.el-upload--picture-card {
    position: relative;

    .el-icon-plus {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  /deep/ .el-upload__tip {
    word-break: break-all;
  }
}
</style>
