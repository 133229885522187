export default {
  taxRateCache (state) {
    return state.taxRateCache;
  },
  supplierCache (state) {
    return state.supplierCache;
  },
  annexNameCache (state) {
    return state.annexNameCache;
  },
  measureUnitCache (state) {
    return state.measureUnitCache;
  },
  jifeiLeimuCache (state) {
    return state.jifeiLeimuCache;
  },
  qualityAssTypeCache (state) {
    return state.qualityAssTypeCache;
  },
  rateCache (state) {
    return state.rateCache;
  },
};
