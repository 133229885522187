<template>
  <vxe-grid
    resizable
    autoResize
    ref="vxeTable"
    :border="border"
    stripe
    :size="size"
    :height="height"
    :max-height="maxHeight"
    :data="tableData"
    :row-id="rowKey"
    rowKey
    :show-footer="showFooter"
    :merge-cells="mergeCells"
    :merge-footer-items="mergeFooterItems"
    :footer-method="footerMethod"
    :highlight-current-row="highlightRow"
    highlight-hover-row
    highlight-current-column
    highlight-hover-column
    :scroll-y="scrollY"
    :columns="columns"
    :checkbox-config="checkboxConfig"
    :radio-config="radioConfig"
    :seq-config="seqConfig"
    :show-header="showHeader"
    :row-class-name="getRowClassName"
    :cell-class-name="getCellClassName"
    @current-change ='handleRowCurrentChange'
    @sort-change="handleSortChanged"
    @checkbox-change="handleCheckboxChanged"
    @radio-change="({row})=>$emit('radio-change', row)"
    @checkbox-all="handleCheckboxAllChecked"
    @toolbar-button-click="handleToolbarButtonClick"
    :toolbarConfig="toolbarConfig"
    :customConfig="customConfig"
    :sort-config="defaultSort"
    style="width: 100%"
    :header-cell-style="headerCellStyle"
    :span-method="spanMethod"
    :edit-rules="validRules"
    :edit-config="editConfig"
    :empty-text="emptyText"
    :header-cell-class-name="headerCellClassName"
  >
    <template #pager>
      <vxe-pager
        :size="size"
        v-if="showPager && pagination && pagination.total"
        :current-page="pagination.currentPage"
        @page-change="handleCurrentChanged"
        :page-sizes="[10, 20, 50, 100, 200]"
        :page-size="pagination.pageSize"
        :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
        :total="pagination.total"
      ></vxe-pager>
    </template>
  </vxe-grid>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'table-with-pagination',
  props: {
    loading: {
      type: Boolean,
      default () {
        return false;
      },
    },
    pagination: {
      type: Object,
      default () {
        return {};
      },
    },
    columnList: {
      type: Array,
      default () {
        return [];
      },
    },
    tableData: {
      type: Array,
      default () {
        return [];
      },
    },
    sortInfo: {
      type: Object,
      validator: function (value) {
        if (value) {
          // key必须包含orderByProperty和orderByType
          return 'orderByProperty' in value && 'orderByType' in value;
        }
        return true;
      },
    },
    rowKey: {
      type: String,
      default: 'id',
    },
    defaultSelected: {
      type: Array,
      default: () => [],
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    footerData: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: [Number, String],
      default: 600,
    },
    size: {
      type: String,
      default: 'small',
    },
    showPager: {
      type: Boolean,
      default: true,
    },
    checkboxConfig: {
      type: Object,
    },
    radioConfig: {
      type: Object,
    },
    scrollY: {
      type: Object,
      default: () => ({
        enabled: true,
        mode: 'default',
        gt: 30,
      }),
    },
    leftToolbar: {
      type: Array,
      default: () => [],
    },
    showRightToolbar: {
      type: Boolean,
      default: false,
    },
    getRowClass: {
      type: Function,
      default: null,
    },
    getHeaderCellClassName: {
      type: Function,
      default: null,
    },
    getCellClass: {
      type: Function,
      default: null,
    },
    mergeCells: [],
    mergeFooterItems: [],
    headerCellStyle: {
      type: Function,
      default: null,
    },
    spanMethod: {
      type: Function,
      default: null,
    },
    validRules: {
      type: Object,
      default: null,
    },
    editConfig: {
      type: Object,
      default: null,
    },
    highlightRow: {
      type: Boolean,
      default: true,
    },
    emptyText: {
      type: String,
      default: '暂无数据',
    },
    border: {
      type: [Boolean, String],
      default: 'full',
    },
  },
  data () {
    return {
      customConfig: {
        storage: false,
        checkMethod: ({ column }) => {
          return !column.params || !column.params.isActionColumn;
        },
      },
    };
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
    toolbarConfig () {
      let buttons = this.leftToolbar.filter(t => (!t.permission || this.actionPermissionMap[t.permission]));
      if (buttons.length > 0 || this.showRightToolbar) {
        return {
          import: false,
          export: false,
          zoom: this.showRightToolbar,
          custom: this.showRightToolbar,
          slots: {
            buttons: ({ x }, h) => { // eslint-disable-line
              return buttons.map(t => {
                if (t.type === 'custom') {
                  return t.render(h);
                } else {
                  return h('el-button', {
                    props: {
                      type: t.status,
                      size: t.size,
                      icon: t.icon,
                      disabled: t.disabled,
                      plain: t.plain,
                    },
                    attrs: {
                      id: t.id,
                    },
                    on: {
                      click: t.click,
                    },
                  }, t.name);
                }
              });
            },
          },
        };
      } else {
        return null;
      }
    },

    defaultSort () {
      if (this.sortInfo) {
        return {
          trigger: 'cell',
          defaultSort: {
            field: this.sortInfo.orderByProperty,
            order: this.sortInfo.orderByType && this.sortInfo.orderByType.toUpperCase() === 'DESC' ? 'desc' : 'asc',
          },
          orders: ['desc', 'asc', null],
          remote: true,
        };
      } else {
        return {};
      }
    },
    seqConfig () {
      return {
        startIndex: 0,
        seqMethod: ({ rowIndex }) => {
          if (this.showPager && this.pagination && Object.keys(this.pagination).length > 0) {
            return (this.pagination.currentPage - 1) * this.pagination.pageSize + rowIndex + 1;
          } else {
            return rowIndex + 1;
          }
        },
      };
    },
    columns () {
      return this.columnList.map(t => {
        if (t.type) {
          return {
            ...t,
            field: t.prop || t.field,
            title: t.label || t.title,
            showOverflow: t.tooltip,
            align: t.align || 'left',
            headerAlign: t.headerAlign || 'left',
            // minWidth: t.minWidth || (t.label ? `${t.label.length * 16 + 20}px` : null),
            width: '80px',
            formatter: this.getColumnFormatter,
            type: t.type === 'index' ? 'seq' : t.type,
          };
        } else if (t.render && !t.header) {
          return {
            ...t,
            field: t.prop || t.field,
            title: t.label || t.title,
            editRender: t.editRender,
            align: t.align || 'left',
            headerAlign: t.headerAlign || 'left',
            showOverflow: t.tooltip,
            minWidth: t.minWidth || (t.label ? `${t.label.length * 16 + 20}px` : null),
            slots: {
              default: ({ row, rowIndex, column }, h) => [t.render(h, { row, column, index: rowIndex })],
            },
            params: {
              isActionColumn: true,
            },
          };
        } else if (!t.render && t.header) {
          return {
            ...t,
            field: t.prop || t.field,
            title: t.label || t.title,
            align: t.align || 'left',
            headerAlign: t.headerAlign || 'left',
            showOverflow: t.tooltip,
            minWidth: t.minWidth || (t.label ? `${t.label.length * 16 + 20}px` : null),
            slots: {
              header: ({ column, columnIndex }, h) => [t.header(h, { column, columnIndex })],
            },
            params: {
              isActionColumn: true,
            },
          };
        } else if (t.render && t.header) {
          return {
            ...t,
            field: t.prop || t.field,
            title: t.label || t.title,
            align: t.align || 'left',
            showOverflow: t.tooltip,
            headerAlign: t.headerAlign || 'left',
            minWidth: t.minWidth || (t.label ? `${t.label.length * 16 + 20}px` : null),
            slots: {
              header: ({ column, columnIndex }, h) => [t.header(h, { column, columnIndex })],
              default: ({ row, rowIndex, column }, h) => [t.render(h, { row, column, index: rowIndex })],
            },
            params: {
              isActionColumn: true,
            },
          };
        } else {
          return {
            ...t,
            field: t.prop || t.field,
            title: t.label || t.title,
            align: t.align || 'left',
            headerAlign: t.headerAlign || 'left',
            editRender: t.editRender,
            showOverflow: t.tooltip,
            minWidth: t.minWidth || (t.label ? `${t.label.length * 16 + 20}px` : null),
            formatter: this.getColumnFormatter(t),
          };
        }
      });
    },
  },
  watch: {
    footerData: {
      deep: true,
      handler () {
        this.$refs.vxeTable.updateFooter();
      },
    },
  },
  methods: {
    getColumns () {
      // 通过exportable来控制是否可导出
      return this.$refs.vxeTable.getColumns().filter(t => {
        const column = this.columnList.find(c => c.prop === t.property);
        return column?.exportable !== false;
      });
    },
    footerMethod ({ columns }) {
      // footerdata = [{
      //     checkTitle: '合集'，
      //     propertyList: '2'，
      //   }，{
      //     checkTitle: '平均'，
      // }]
      // checkTitle 为选择框下方格式化字段（固定）
      // action 未传入数据或者数据为空 统一处理为 ‘-’，操作按钮一栏prop传入为action
      // this.$refs.vxeTable.clearMergeFooterItems();
      if (!this.footerData.length) return [];
      const arrData = [];
      this.footerData.forEach((item, index) => {
        arrData[index] = [];
      });
      this.footerData.forEach((item, index) => {
        columns.forEach((column, columnIndex) => {
          if (columnIndex === 0) {
            arrData[index].push(item.checkTitle);
          } else {
            if (
              column.property !== 'action'
              && (item[column.property] === undefined || item[column.property] === null)
            ) {
              arrData[index][columnIndex] = '-';
            } else {
              arrData[index][columnIndex] = item[column.property];
            }
          }
        });
      });
      return arrData;
    },
    clearCheckboxReserve () {
      return this.$refs.vxeTable.clearCheckboxReserve();
    },
    clearCheckboxRow () {
      return this.$refs.vxeTable.clearCheckboxRow();
    },
    clearRadioRow () {
      this.$refs.vxeTable.clearRadioRow();
    },
    setCheckboxRow (rows, checked) {
      return this.$refs.vxeTable.setCheckboxRow(rows, checked);
    },
    setRadioRow (row) {
      return this.$refs.vxeTable.setRadioRow(row);
    },
    handleToolbarButtonClick ({ code, button, $event }) {
      if (this.leftToolbar) {
        let btnConfig = this.leftToolbar.find(t => t.code === code);
        btnConfig.click && btnConfig.click();
      }
      this.$emit('toolbar-button-click', code, button, $event);
    },
    getRowClassName ({ row, rowIndex }) {
      if (this.getRowClass) {
        return this.getRowClass(row, rowIndex);
      }
    },
    headerCellClassName ({ column }) {
      if (this.getHeaderCellClassName) {
        return this.getHeaderCellClassName(column);
      }
    },
    getCellClassName ({ row, rowIndex, column }) {
      if (this.getCellClass) {
        return this.getCellClass(row, column, rowIndex);
      }
    },
    handleCheckboxChanged ({checked, row}) {
      this.$emit('select', { row, checked });
      this.handleSelectionChange();
    },
    handleCheckboxAllChecked ({ records, checked }) {
      this.$emit('select-all', { selections: records, checked });
      this.handleSelectionChange();
    },
    handleSelectionChange () {
      let selections = this.$refs.vxeTable.getCheckboxRecords();
      if (this.checkboxConfig && this.checkboxConfig.reserve) {
        selections = selections.concat(this.$refs.vxeTable.getCheckboxReserveRecords());
      }
      this.$emit('selection-change', selections);
    },
    handleSortChanged ({ order, property }) {
      if (order) {
        order = order.indexOf('asc') >= 0 ? 'ASC' : 'DESC';
      } else {
        property = null;
      }
      this.$emit('update:sortInfo', {
        orderByProperty: property,
        orderByType: order,
      });
      this.$emit('sort-change', property, order);
    },
    handleCurrentChanged ({currentPage, pageSize}) {
      let { total } = this.pagination;
      this.$emit('update:pagination', { total, pageSize, currentPage });
      this.$emit('current-change', { pageSize, currentPage });
    },
    indexMethod (index) {
      let { currentPage, pageSize } = this.pagination;
      return (currentPage - 1) * pageSize + index + 1;
    },
    getColumnFormatter (column) {
      let emptyPlaceholder = ({ cellValue }) => {
        return cellValue === null || cellValue === undefined || cellValue === '' ? '--' : cellValue;
      };
      if (column.format) {
        return ({ cellValue }) => {
          let formatValue = column.format(cellValue);
          return emptyPlaceholder({ cellValue: formatValue });
        };
      } else {
        return emptyPlaceholder;
      }
    },
    handleRowCurrentChange ({row, rowIndex}) {
      this.$emit('handleRowCurrentChange', row, rowIndex);
    },
  },
  components: {
    // CustomColumn: {
    //   props: {
    //     row: Object,
    //     renderContent: Function,
    //     index: Number,
    //     column: {
    //       type: Object,
    //       default: null,
    //     },
    //   },
    //   render: function (h) {
    //     const params = {
    //       row: this.row,
    //       column: this.column,
    //       index: this.index,
    //     };
    //     return this.renderContent(h, params);
    //   },
    // },
  },
  deactivated () {
    // 组件失活时，关闭tooltip
    setTimeout(() => {
      this.$refs && this.$refs.vxeTable && this.$refs.vxeTable.closeTooltip();
    }, 1000);
  },
};
</script>

<style scoped lang="scss">
/deep/.vxe-grid--pager-wrapper {
    margin-top: 16px;
}

/deep/ .vxe-buttons--wrapper {
  &> *:not(:first-child) {
    margin-left: 8px;
  }
}
/deep/ .el-button i +  span{
  margin-left: 0.5em;
}
/deep/ .vxe-cell .table-action{
  cursor: pointer;
  color: #5195EB;
  &.table-action-delete{
    color: #F56C6C;
  }
  &.table-action-warning{
    color:#F59A23;
  }
}
/deep/ .vxe-cell .table-action + .el-dropdown{
  margin-left: 10px;
}
/deep/ .vxe-cell .table-action:not(:first-child) {
  margin-left: 10px;
}
</style>
