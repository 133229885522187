import Vue from 'vue';
import XEUtils from 'xe-utils';
import zhCN from 'vxe-table/lib/locale/lang/zh-CN';
import {
  VXETable,
  Header,
  Column,
  Toolbar,
  Grid,
  Pager,
  Table,
  Button,
  Select,
  Option,
  Filter,
  Tooltip,
  Edit,
  Switch,
  Validator,
  Input,
  Checkbox,
  CheckboxGroup,
  Footer,
} from 'vxe-table';

import 'vxe-table/lib/style.css';
VXETable.setup({
  zIndex: 8888,
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
});
Vue.use(VXETable)
  .use(Header)
  .use(Edit)
  .use(Validator)
  .use(Column)
  .use(Toolbar)
  .use(Grid)
  .use(Pager)
  .use(Button)
  .use(Select)
  .use(Option)
  .use(Filter)
  .use(Tooltip)
  .use(Switch)
  .use(Table)
  .use(Input)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Footer);
