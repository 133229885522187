export const getFirstUrl = (menu) =>{
  if (menu[0].url) {
    return menu[0].url;
  } else {
    if (menu[0].children && menu[0].children.length) {
      return getFirstUrl(menu[0].children);
    } else {
      return '';
    }
  }
};
export default {
  menuTree (state) {
    return state.menuTree;
  },
  menuIdMap (state) {
    return state.menuIdMap;
  },
  menuUrlMap (state) {
    return state.menuUrlMap;
  },
  actionPermissionMap (state) {
    return state.actionPermissionMap;
  },
  authAllMap (state) {
    return state.authAllMap;
  },
  firstUrl (state) {
    let menuTree = state.menuTree;
    if (!menuTree.length) {
      return '/workbench';
    }
    let url = getFirstUrl(menuTree);
    return url || '/workbench';
  },
};
