import { getOptionListMap, getSupplier, getRate } from '@/api/contract';
export default {
  async getTaxRate ({ commit }) {
    const { body } = await getOptionListMap({
      enableFlag: 1,
      typeCode: 'TAX_RATE',
    });

    commit('UPDATE_TAX_RATE', body?.TAX_RATE || []);
  },
  async getSupplier ({ commit }, data) {
    const { body } = await getSupplier(data);

    commit('UPDATE_SUPPLIER', body);
  },
  async getAnnexName ({ commit }) {
    const { body } = await getOptionListMap({
      enableFlag: 1,
      typeCode: 'ANNEX_NAME',
    });

    commit('UPDATE_ANNEX_NAME', body?.ANNEX_NAME || []);
  },
  async getMeasureUnit ({ commit }) {
    const { body } = await getOptionListMap({
      enableFlag: 1,
      typeCode: 'MEASURE_UNIT',
    });

    commit('UPDATE_MEASURE_UNIT', body?.MEASURE_UNIT || []);
  },
  async getJifeiLeimu ({ commit }) {
    const { body } = await getOptionListMap({
      enableFlag: 1,
      typeCode: 'JIFEI_LEIMU',
    });

    commit('UPDATE_JIFEI_LEIMU', body?.JIFEI_LEIMU || []);
  },
  async getQualityAssType ({ commit }) {
    const { body } = await getOptionListMap({
      enableFlag: 1,
      typeCode: '7hCUTL',
    });

    commit('UPDATE_QUALITY_ASS_TYPE', body?.['7hCUTL'] || []);
  },
  async getRate ({ commit }) {
    const { body } = await getRate();

    commit('UPDATE_RATE', body || 0);
  },
};
